var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" d-flex justify-content-center"},[_c('div',{staticClass:"card my-2 w-75"},[_c('div',{staticClass:"m-5 d-flex justify-content-center"},[_c('div',{staticClass:"mx-5 container "},[_c('validation-observer',{ref:"spec_item_form",attrs:{"tag":"form"}},[_c('label',{attrs:{"for":"select"}},[_vm._v("Select Budget Group")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.budget_group,"label":"name","reduce":function (option) { return option.key; },"disabled":""},on:{"input":_vm.clearSelects},model:{value:(_vm.form.budget_group),callback:function ($$v) {_vm.$set(_vm.form, "budget_group", $$v)},expression:"form.budget_group"}}),_c('br'),_c('validation-provider',{attrs:{"name":"Element to attach","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"select"}},[_vm._v("Attach to Element")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.form.budget_group === 'CNST'
                  ? _vm.cnst
                  : _vm.form.budget_group === 'FF&E'
                    ? _vm.ffe
                    : _vm.avit,"label":_vm.form.budget_group === 'CNST' ? 'construction_spec' : 'element_name'},model:{value:(_vm.selected_item),callback:function ($$v) {_vm.selected_item=$$v},expression:"selected_item"}}),_c('small',{staticClass:"text-muted"},[_vm._v("This Spec will appear as spec swap.")]),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')])]}}])}),(_vm.selected_item!=null)?_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('b',[_vm._v(" Base Spec")])]),_c('div',{staticClass:"col-4"},[_c('b',[_vm._v("New Spec")])]),_c('div',{staticClass:"col-4"},[_c('b',[_vm._v("Name")])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"ml-1"},[_c('p',[_vm._v(" "+_vm._s(_vm.form.budget_group === 'CNST' ? _vm.selected_item.construction_spec : _vm.selected_item.element_name)+" ")])])]),_c('div',{staticClass:"col-4 mt-0 pt-0"},[_c('validation-observer',{ref:"spec_item_form",attrs:{"tag":"form"}},[_c('validation-provider',{attrs:{"name":"Spec Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')])]}}],null,false,765279409)})],1)],1),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4"},[_c('b',[_vm._v("Manufacturer")])]):_vm._e(),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4"},[(_vm.form.budget_group !== 'CNST')?_c('div',{},[_c('p',[_vm._v(" "+_vm._s(_vm.selected_item.vendor)+" ")])]):_vm._e()]):_vm._e(),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4 py-0 m-0 pb-1"},[(_vm.form.budget_group !== 'CNST')?_c('div',[_c('b-form-input',{attrs:{"placeholder":"[OPEN SPEC]"},model:{value:(_vm.form.manufacturer),callback:function ($$v) {_vm.$set(_vm.form, "manufacturer", $$v)},expression:"form.manufacturer"}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-4"},[_c('b',[_vm._v(" Unit COGS")])]),_c('div',{staticClass:"col-4"},[(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col"},[_c('p',[_vm._v(" "+_vm._s(Number(_vm.selected_item.unit_cost).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])]):_c('div',[_c('div',{staticClass:"col"},[_c('p',[_vm._v(" "+_vm._s(Number(_vm.selected_item.unit_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])])])]),_c('div',{staticClass:"col-4 mt-0 pt-0"},[_c('div',[_c('validation-provider',{attrs:{"name":"Unit COGS","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"placeholder":"0","type":"number"},model:{value:(_vm.form.cogs_per_unit),callback:function ($$v) {_vm.$set(_vm.form, "cogs_per_unit", $$v)},expression:"form.cogs_per_unit"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')])]}}],null,false,2803085546)})],1)]),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4"},[_c('b',[_vm._v("Markup")])]):_vm._e(),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4 "},[_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(Number(((_vm.selected_item.supply_price-_vm.selected_item.unit_cost)/(_vm.selected_item.unit_cost))*100).toFixed(2))+" % ")])]):_vm._e(),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4 mt-0 pt-0"},[(_vm.form.budget_group !== 'CNST')?_c('div',[_c('validation-provider',{attrs:{"name":"Markup","rules":{required:_vm.form.budget_group !== 'CNST'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"placeholder":"0","type":"number"},model:{value:(_vm.form.markup),callback:function ($$v) {_vm.$set(_vm.form, "markup", $$v)},expression:"form.markup"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')])]}}],null,false,3419086964)})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-4"},[_c('b',[_vm._v(" Unit Price")])]),_c('div',{staticClass:"col-4"},[(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col"},[_c('p',[_vm._v(" "+_vm._s(Number(_vm.selected_item.supply_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])]):_c('div',{staticClass:"col"},[_c('p',[_vm._v(" "+_vm._s(Number(_vm.selected_item.unit_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])])]),_c('div',{staticClass:"col-4",class:{'mt-0 pt-0':_vm.form.budget_group === 'CNST'}},[(_vm.form.budget_group !== 'CNST')?_c('div',[_vm._v(" "+_vm._s(Number(_vm.total_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Unit Price","rules":{required:_vm.form.budget_group === 'CNST'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"number","step":"0.01"},model:{value:(_vm.form.price_per_unit),callback:function ($$v) {_vm.$set(_vm.form, "price_per_unit", $$v)},expression:"form.price_per_unit"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')])]}}],null,false,2437337665)})],1)]),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4"},[_c('b',[_vm._v(" D&I")])]):_vm._e(),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4"},[(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col"},[_c('p',[_vm._v(" "+_vm._s(Number(_vm.selected_item.unit_delivery_w_install).toLocaleString( "en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", } ))+" ")])]):_vm._e()]):_vm._e(),(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col-4 pt-0"},[(_vm.form.budget_group !== 'CNST')?_c('div',[_c('validation-provider',{attrs:{"name":"Delivery & Install","rules":{required:_vm.form.budget_group !== 'CNST'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"placeholder":"22.5","type":"number"},model:{value:(_vm.form.unit_delivery_install),callback:function ($$v) {_vm.$set(_vm.form, "unit_delivery_install", $$v)},expression:"form.unit_delivery_install"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')])]}}],null,false,4271907700)})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-4"},[_c('b',[_vm._v("Unit Price w/ D&I")])]),_c('div',{staticClass:"col-4"},[(_vm.form.budget_group !== 'CNST')?_c('div',{staticClass:"col"},[_c('p',[_vm._v(" "+_vm._s(Number(_vm.selected_item.total_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])]):_c('div',{staticClass:"col"},[_c('p',[_vm._v(" "+_vm._s(Number(_vm.selected_item.unit_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])])]),_c('div',{staticClass:"col-4"},[_c('div',[_vm._v(" "+_vm._s(Number(_vm.total_price_w_delivery).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])])]):_vm._e()],1),_c('div',{staticClass:"w-100 my-2"},[_c('h5',[_vm._v("Linked Spec Sets")]),_c('div',{staticStyle:{"border":"solid #f3f2f7 2px"}},[_c('div',{staticClass:"my-2 mx-1"},_vm._l((_vm.form.specification_swap_sets_names),function(t,index){return (_vm.form.specification_swap_sets_names.length>0)?_c('b-badge',{key:index,staticClass:"badge-mine",staticStyle:{"margin":"5px"}},[_vm._v(_vm._s(t)+" ")]):_c('b-badge',[_vm._v(" Spec is not linked yet ")])}),1)])]),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"my-2",attrs:{"variant":"primary"},on:{"click":_vm.addSpecificationItem}},[_vm._v("Save Spec ")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }